import {Observable} from 'rxjs/Observable';
import 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceMain} from './service-main.service';
import {Router} from '@angular/router';
import {PersistenceService} from 'angular-persistence';

@Injectable()
export class OsService extends ServiceMain {
  constructor(private _http: HttpClient,
              private routers: Router,
              private persistenceService: PersistenceService) {
    super(_http, routers, persistenceService);
  }

  get(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'os');
  }

  getEsp(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'os/esp');
  }

  attItem(dados: any): Observable<any> {
    return this.executaPOST(dados, 'os/item');
  }

  encerrarItem(dados: any): Observable<any> {
    return this.executaPOST(dados, 'os/itemEncerrar');
  }

  ativarCred(dados: any): Observable<any> {
    return this.executaPOST(dados, 'os/ativarCred');
  }

  bloquearCred(dados: any): Observable<any> {
    return this.executaPOST(dados, 'os/bloquearCred');
  }
}
