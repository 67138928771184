import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UserService} from './user.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError(err => {
          if (err.status === 401) {
            this.userService.logout();

            swal({
              title: 'Token Expirado!',
              text: 'Por favor, entre novamente no sistema.',
              type: 'error',
              closeOnConfirm: true
            }, function () {
              // auto logout on unauthorized response
              location.reload();
            });
          }

          const error = err.error.message || err.statusText;
          return throwError(error);
        })
      )
  }

}

