import {UserService} from './services/user.service';
import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent {
  constructor(private userService: UserService, private router: Router) {
    try {
      window.stop();
    } catch (exception) {
      document.execCommand('Stop');
    }

    this.userService.logout();
    this.router.navigate(['']);
  }
}
