import {Component, ViewChild, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.html'
})

export class LoginComponent implements OnInit {
  errorLogin = false;
  textErrorLogin = 'Digite seu usuario e senha.';
  logando = false;

  logoCima = '';

  @ViewChild('lembrarUsuario', {static: true}) lembrarUsuario: ElementRef;
  @ViewChild('usuario', {static: true}) usuario: ElementRef;

  constructor(private userService: UserService, private router: Router,
              private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      if (params['empresa']) {
        if (params['empresa'] == '8') {
          this.logoCima = '../assets/imagens/img/cima8.png';
          localStorage.setItem('logoCima', this.logoCima);
        } else if (params['empresa'] == '11') {
          this.logoCima = '../assets/imagens/img/cima11.png';
          localStorage.setItem('logoCima', this.logoCima);
        } else {
          this.logoCima = '';
          localStorage.setItem('logoCima', '');
        }
      }
    });

    if (this.logoCima == '' && localStorage.getItem('logoCima')) {
      this.logoCima = localStorage.getItem('logoCima');
    }
  }

  verificaLocalStore(): any {
    const me = this;

    setTimeout(function () {
      if (localStorage.getItem('login') != null && localStorage.getItem('login') !== undefined) {
        $(me.usuario.nativeElement).val(localStorage.getItem('login'));
        $(me.lembrarUsuario.nativeElement).prop('checked', true);
      } else {
        $(me.lembrarUsuario.nativeElement).prop('checked', false);
      }
    }, 1);
  }

  ngOnInit(): any {
    $('body').addClass('login');
    this.verificaLocalStore();
  }

  logar(event: any, usuario: string, senha: string, lembrarUsuario: any) {
    event.preventDefault();

    if (this.logando) {
      return;
    }

    if (usuario != '' && senha != '') {
      this.logando = true;

      this.userService.login({usuario: usuario, senha: senha}, $(lembrarUsuario).prop('checked'))
        .subscribe(
          response => {
            if (response.success) {
              this.router.navigate(['/inicio']);
            } else {
              this.textErrorLogin = response.message;
              this.errorLogin = true;
              this.logando = false;

              this.pulseError();
            }
          }
        );
    } else {
      this.textErrorLogin = 'Digite seu usuario e senha';
      this.errorLogin = true;

      this.pulseError();
    }
  }

  private pulseError() {
    setTimeout(function () {
      $('.errorLogin').pulsate({
        color: '#e73d4a',
        repeat: false
      });
    }, 1);
  }
}
