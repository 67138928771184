import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filtrarMedicos',
  pure: false
})
export class FilterMedicosPipe implements PipeTransform {
  transform(items: any[], status: string): any {
    if (status == '2') {
      return items.filter(item => item.deletado != '1');
    }

    return items.filter(item => (item.status == status || item.novo == 'true') && item.deletado != '1');
  }
}
