import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OsService} from '../../../services/os.service';
import {ModalDirective} from 'ngx-bootstrap';

declare let Metronic: any;
declare let Layout: any;

@Component({
  selector: 'app-osesp',
  templateUrl: './osesp.html'
})

export class OSEspComponent {
  carregado = true;

  os: any;
  itensOs = [];
  codigoOs: any;

  observacao: string;
  index: any;
  @ViewChild('modalObservacao', {static: true}) modalObservacao: ModalDirective;

  constructor(private osService: OsService,
              private route: ActivatedRoute,
              private router: Router) {
    this.route.params.subscribe(params => {
      if (params['codigo']) {
        this.codigoOs = params['codigo'];
        this.init();
      }
    });
  }

  init(): void {
    this.carregado = false;

    this.osService.getEsp({codigo: this.codigoOs})
      .subscribe(
        response => {
          this.itensOs = response.itensOs;
          this.os = response.os;

          this.carregado = true;
          this.initMetronic();
        }
      );
  }

  addObjservacoes(index) {
    this.index = index;
    this.observacao = this.itensOs[index].AB7_OCORRE;
    this.modalObservacao.show();
  }

  guardarObservacao() {
    this.itensOs[this.index].AB7_OCORRE = this.observacao;
    this.modalObservacao.hide();
  }

  initMetronic(): void {
    Metronic.init();
    Layout.init();

    let body = 'body';
    $(body).removeClass('login');
    $(body).addClass('page-header-fixed page-sidebar-closed-hide-logo page-container-bg-solid page-content-white page-md page-sidebar-closed');
  }

  salvarItem(item): void {
    let me = this;
    let previousWindowKeyDown = window.onkeydown;

    swal({
      title: 'Item OS',
      text: 'Deseja alterar esse item da OS?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    }, function () {
      window.onkeydown = previousWindowKeyDown;

      me.osService.attItem(item)
        .subscribe(
          response => {
            if (response.success) {
              swal({
                title: 'Item OS',
                text: 'Item da OS alterado com sucesso.',
                type: 'success',
                closeOnConfirm: true
              }, function () {
                me.init();
              });
            } else {
              swal({
                title: 'Item OS',
                text: response.message,
                type: 'error',
                closeOnConfirm: true
              });
            }
          }
        );
    });
  }

  encerrarItem(item): void {
    let me = this;
    let previousWindowKeyDown = window.onkeydown;

    swal({
      title: 'Item OS',
      text: 'Deseja encerrar esse item da OS?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    }, function () {
      window.onkeydown = previousWindowKeyDown;

      me.osService.encerrarItem(item)
        .subscribe(
          response => {
            if (response.success) {
              swal({
                title: 'Item OS',
                text: 'Item da OS encerrado com sucesso.',
                type: 'success',
                closeOnConfirm: true
              }, function () {
                me.init();
              });
            } else {
              swal({
                title: 'Item OS',
                text: response.message,
                type: 'error',
                closeOnConfirm: true
              });
            }
          }
        );
    });
  }

  voltar(): void {
    this.router.navigate(['os']);
  }
}
