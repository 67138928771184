import {Injectable} from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import {PersistenceService, StorageType} from 'angular-persistence';

@Injectable()
export class AuthLogin implements CanActivate {
  constructor(private router: Router,
              private persistenceService: PersistenceService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    if (!this.persistenceService.get('usuario', StorageType.LOCAL)) {
      return true;
    }

    // logged in so redirect to abastecimento page
    this.router.navigate(['inicio']);
    return false;
  }
}
