import 'rxjs/Rx';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class CorreiosService {
  // Correios
  constructor(private _correios: HttpClient) {

  }

  getCepCorreios(cep: string): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      observe: 'response'
    };

    return this._correios.get('http://api.postmon.com.br/v1/cep/' + cep,
      options
    ).pipe(
      map((res: any) => {
        return res.body;
      })
    );
  }
}
