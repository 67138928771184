import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'cns'
})
export class CnsPipe implements PipeTransform {
  transform(value: string, args: any[]) {

    if (value && value.length > 9) {
      return value.substring(0, 3) + '.' +
        value.substring(3, 7) + '.' +
        value.substring(7, 11) + '-' +
        value.substring(11);
    }

    return '';
  }
}
