import {Component, OnInit} from '@angular/core';

declare let Metronic: any;
declare let Layout: any;

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.html'
})

export class InicioComponent implements OnInit {
  ngOnInit(): any {
    Metronic.init();
    Layout.init();

    let body = 'body';
    $(body).removeClass('login');
    $(body).addClass('page-header-fixed page-sidebar-closed-hide-logo page-container-bg-solid page-content-white page-md page-sidebar-closed');
  }
}
