import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ProspectService} from '../../services/prospect.service';
import {Router} from '@angular/router';

declare let Metronic: any;
declare let Layout: any;

@Component({
  selector: 'app-prospect',
  templateUrl: './prospect.html'
})
export class ProspectComponent implements OnInit {
  carregado = false;

  dados = [];

  prospService: any;

  @ViewChild('filtrosAbrir', {read: ElementRef, static: false}) filtrosAbrir: ElementRef;

  constructor(private prospectService: ProspectService,
              private router: Router) {
    this.prospService = this.prospectService;
  }

  ngOnInit(): any {
    this.initMetronic();
    this.inicio(true);
  }

  abrirFiltros(): void {
    $(this.filtrosAbrir.nativeElement).collapse('toggle');
  }

  initMetronic(): void {
    Metronic.init();
    Layout.init();

    let body = 'body';
    $(body).removeClass('login');
    $(body).addClass('page-header-fixed page-sidebar-closed-hide-logo page-container-bg-solid page-content-white page-md page-sidebar-closed');
  }

  inicio(primeiro?: boolean): void {
    if (!this.carregado && !primeiro) {
      return;
    }

    this.carregado = false;

    this.prospService.get(this.prospService.filtro)
      .subscribe(
        response => {
          this.dados = response.dados;
          this.carregado = true;

          this.prospService.filtro.paginaFinal = response.paginaFinal;
        }
      );
  }

  paginacaoDireto(): any {
    this.inicio();
  }

  paginacaoPrimeiro(): any {
    this.prospService.filtro.paginaAtual = 1;
    this.inicio();
  }

  paginacaoVoltar(): any {
    this.prospService.filtro.paginaAtual -= 1;
    this.inicio();
  }

  paginacaoAvancar(): any {
    this.prospService.filtro.paginaAtual += 1;
    this.inicio();
  }

  paginacaoUltimo(): any {
    this.prospService.filtro.paginaAtual = this.prospService.filtro.paginaFinal;
    this.inicio();
  }

  abrir(recno): void {
    this.router.navigate(['prospect/' + recno])
  }
}
