import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UsuariosService} from '../../services/usuarios.service';

declare let Metronic: any;
declare let Layout: any;

@Component({
  selector: 'app-senha',
  templateUrl: './senha.html'
})

export class SenhaComponent implements OnInit {
  constructor(private usuariosService: UsuariosService, private router: Router) {
  }

  ngOnInit(): any {
    Metronic.init();
    Layout.init();

    let body = 'body';
    $(body).removeClass('login');
    $(body).addClass('page-header-fixed page-sidebar-closed-hide-logo page-container-bg-solid page-content-white page-md page-sidebar-closed');
  }

  alterarSenha(senhaAtual: string, novaSenha: string): void {
    let me = this;
    if (senhaAtual != '' && novaSenha != '') {
      this.usuariosService.validaSenha({atual: senhaAtual})
        .subscribe(
          response => {
            if (response) {
              if (senhaAtual == novaSenha) {
                swal({
                  title: 'Senhas iguais!',
                  text: 'Por favor, a nova senha não pode ser igual a senha atual.',
                  type: 'error',
                  closeOnConfirm: true
                });
              } else {
                this.usuariosService.attSenha({nova: novaSenha})
                  .subscribe(
                    responsee => {
                      if (responsee) {
                        swal({
                          title: 'Senha alterada com sucesso!',
                          text: '',
                          type: 'success',
                          closeOnConfirm: true
                        }, function () {
                          me.router.navigate(['']);
                        });
                      }
                    }
                  );
              }
            } else {
              swal({
                title: 'Senha atual incorreta!',
                text: 'Por favor, informe a senha atual corretamente.',
                type: 'error',
                closeOnConfirm: true
              });
            }
          }
        );
    } else {
      swal({
        title: 'Campos vazios!',
        text: 'Por favor, informe a senha atual e a nova senha.',
        type: 'error',
        closeOnConfirm: true
      });
    }
  }
}
