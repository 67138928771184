import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class DateBrPipe implements PipeTransform {
  transform(date: any): any {
    return date.substr(6, 2) + '/' +
      date.substr(4, 2) + '/' +
      date.substr(0, 4);
  }
}
