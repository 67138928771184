import {Observable} from 'rxjs/Observable';
import 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceMain} from './service-main.service';
import {Router} from '@angular/router';
import {PersistenceService} from 'angular-persistence';

@Injectable()
export class CredenciadosService extends ServiceMain {
  filtro = {
    codtn: '',
    cgc: '',
    razao: '',
    fantasia: '',
    endereco: '',
    datade: '',
    dataate: '',
    pendentes: false,
    paginaAtual: 1,
    paginaFinal: 1,
    limite: '10'
  };

  constructor(private _http: HttpClient,
              private routers: Router,
              private persistenceService: PersistenceService) {
    super(_http, routers, persistenceService);
  }

  get(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'credenciados');
  }

  add(dados: any): Observable<any> {
    return this.executaPOST(dados, 'credenciados');
  }

  getEsp(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'credenciados/esp')
  }

  getPagamentos(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'credenciados/pagamentos')
  }

  getCaptcha(): Observable<any> {
    return this.executaGET({}, 'credenciados/captcha')
  }

  importar(dados: any): Observable<any> {
    return this.executaPOST(dados, 'credenciados/importar')
  }

  getCombos(): Observable<any> {
    return this.executaGET({}, 'credenciados/combos')
  }

  getCidades(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'credenciados/cidades')
  }

  confirmaPagamento(dados: any): Observable<any> {
    return this.executaPOST(dados, 'credenciados/confirmaPagamento');
  }

  veridicarCGC(dados: any): Observable<any> {
    return this.executaPOST(dados, 'credenciados/verificarCGC');
  }

  getFilterComboRamo(dados: any): Observable<any> {
    return this.executaGET(dados, 'credenciados/filtercomboramo');
  }

  getCepCorreios(dados: any): Observable<any> {
    return this.executaGET(dados, 'credenciados/getCepCorreios');
  }
}
