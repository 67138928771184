import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CredenciadosService} from '../../../services/credenciados.service';
import {ModalDirective} from 'ngx-bootstrap';
import {CorreiosService} from '../../../services/correios.services';
import {ActivatedRoute, Router} from '@angular/router';
import {ProspectService} from '../../../services/prospect.service';

declare let Metronic: any;
declare let Layout: any;

@Component({
  selector: 'app-prospectEsp',
  templateUrl: './prospectEsp.html',
  styleUrls: ['./prospectEsp.css']
})

export class ProspectEspComponent implements OnInit {
  carregado = true;
  carregadoCorreio = true;
  carregadoCorreioCorres = true;

  salvando = false;

  estados = [];
  cidades = [];

  observacoes = [];
  showRow = [];

  obsNova = {
    ZZT_DTINC: '',
    ZZT_HRINC: '',
    ZZT_OBS: '',
    ZZT_DTRET: '',
    addStatus: false,
    delStatus: false,
    mostrar: false
  };

  recnoProspect = '';

  prospect = {
    codigo: '',
    cnpj: '',
    dataInclusao: '',
    prioridade: '',
    dataCredenc: '',
    dias: '',
    nome: '',
    status: '',
    motivo: '',
    email: '',
    ddd: '',
    telefone1: '',
    telefone2: '',
    telefone3: '',
    contato: '',

    cep: '',
    endereco: '',
    bairro: '',
    uf: '',
    cidade: '',

    tipo: '',
    responsavel: '',
    solicitante: '',
    cliente: '',
    qtdUsuarios: '',
    ticketMedio: '',
    vendedor: '',

    fechamento: 'Q',
    diaFechamento: '',
    prazoPagamento: '',
    valorTaxa2: '',
    taxaAdm: false,
    valorTaxa: '',

    ura: false,
    web: false,
    pos: false,
    tef: false,
    pago: false,
    cielo: false
  };

  @ViewChild('modalReceita', {static: false}) modalReceita: ModalDirective;

  @ViewChild('valortaxa2', {static: false}) valortaxa2: ElementRef;
  @ViewChild('valortaxa', {static: false}) valortaxa: ElementRef;

  @ViewChild('socio1cpf', {static: false}) socio1cpf: ElementRef;
  @ViewChild('socio2cpf', {static: false}) socio2cpf: ElementRef;

  @ViewChild('cgc', {static: false}) cgc: ElementRef;
  @ViewChild('valorPOS', {static: false}) valorPOS: ElementRef;

  constructor(private prospectService: ProspectService,
              private credenciadoService: CredenciadosService,
              private correiosService: CorreiosService,
              private router: Router,
              private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      if (params['codigo']) {
        this.recnoProspect = params['codigo'];

        this.carregado = false;

        this.prospectService.getEsp({codigo: this.recnoProspect})
          .subscribe(
            response => {

              this.prospect = response.prospect;

              if (this.prospect.prazoPagamento != '') {
                this.prospect.prazoPagamento = parseInt(this.prospect.prazoPagamento).toString();
              }

              if (this.prospect.dias != '') {
                this.prospect.dias = parseInt(this.prospect.dias).toString();
              }

              if (this.prospect.qtdUsuarios != '') {
                this.prospect.qtdUsuarios = parseInt(this.prospect.qtdUsuarios).toString();
              }

              if (this.prospect.ticketMedio != '') {
                this.prospect.ticketMedio = parseInt(this.prospect.ticketMedio).toString();
              }

              if (this.prospect.uf != '') {
                this.getCidades(this.prospect.uf, true);
              }

              this.carregado = true;

              if (this.prospect.fechamento.trim() == '') {
                this.prospect.fechamento = 'Q';
              }

              this.observacoes = response.observacoes;

              this.initMask();
            }
          );
      }
    });
  }

  ngOnInit(): any {
    this.initMetronic();

    this.credenciadoService.getCombos()
      .subscribe(
        response => {
          this.estados = response.estados;
        }
      );

    this.initMask();
  }

  initMask(): void {
    setTimeout(function () {
      let maskFone = '.maskFone';
      let maskCPF = '.maskCPF';
      let maskCEP = '.maskCEP';
      let maskCNPJ = '.maskCNPJ';
      let maskMoeda1 = '.maskMoeda1';
      let maskMoeda2 = '.maskMoeda2';
      let maskData = '.maskData';

      $(maskFone).unmask();
      $(maskCPF).unmask();
      $(maskCEP).unmask();
      $(maskCNPJ).unmask();
      $(maskMoeda1).unmask();
      $(maskMoeda2).unmask();
      $(maskData).unmask();

      $(maskFone).mask('0000-00000');
      $(maskCPF).mask('000.000.000-00', {placeholder: '___.___.___-__'});
      $(maskCEP).mask('00000-000', {placeholder: '_____-__'});
      $(maskCNPJ).mask('00.000.000/0000-00', {placeholder: '__.___.___/____-__'});
      $(maskMoeda1).mask('#.##0,00', {reverse: true});
      $(maskMoeda2).mask('#.##0,00', {reverse: true});
      $(maskData).mask('00/00/0000', {placeholder: '__/__/____'});
    }, 1);
  }

  initMetronic(): void {
    Metronic.init();
    Layout.init();

    let body = 'body';
    $(body).removeClass('login');
    $(body).addClass('page-header-fixed page-sidebar-closed-hide-logo page-container-bg-solid page-content-white page-md page-sidebar-closed');
  }

  pesquisarCep() {
    this.carregadoCorreio = false;

    this.correiosService.getCepCorreios(this.prospect.cep)
      .subscribe(
        response => {
          if (response) {
            this.prospect.endereco = response.logradouro;
            this.prospect.bairro = response.bairro;
            this.prospect.uf = response.estado;
            this.prospect.cidade = this.removerAcentos(response.cidade.toUpperCase());

            this.getCidades(response.estado, true);

            this.carregadoCorreio = true;
          }
        },
        error => {
          if (error.status == 404) {
            swal({
              title: 'CEP Inválido!',
              text: 'Desculpa, mais o cep informado não foi encontrado.',
              type: 'error',
              closeOnConfirm: true
            });
          }
        }
      );
  }

  getCidades(estado, inicio) {
    if (!inicio) {
      this.prospect.cidade = '';
    }

    this.credenciadoService.getCidades({uf: estado})
      .subscribe(
        response => {
          this.cidades = response.cidades;
        }
      );
  }

  enviar(): void {
    if (this.prospect.status == 'S' && this.prospect.motivo == '') {
      swal({
        title: 'Motivo',
        text: 'Favor informar o motivo do não interesse do prospect',
        type: 'error',
        closeOnConfirm: true
      });
      return;
    }

    if (this.prospect.status != 'S') {
      this.prospect.motivo = '';
    }

    if (this.prospect.status == 'C') {
      if (this.prospect.cnpj.trim() == '') {
        swal({
          title: 'CNPJ',
          text: 'Favor informar o CNPJ do prospect',
          type: 'error',
          closeOnConfirm: true
        });
        return;
      }

      if (this.prospect.diaFechamento.toString().trim() == '') {
        swal({
          title: 'Dia do Fechamento',
          text: 'Favor informar o Dia do Fechamento do prospect',
          type: 'error',
          closeOnConfirm: true
        });
        return;
      }

      if (this.prospect.prazoPagamento.toString().trim() == '' || this.prospect.prazoPagamento.toString().trim() == '0') {
        swal({
          title: 'Prazo de Pagamento',
          text: 'Favor informar o Prazo de Pagamento do prospect',
          type: 'error',
          closeOnConfirm: true
        });
        return;
      }

      let valorTaxa2 = $(this.valortaxa2.nativeElement).val().toString();

      if (valorTaxa2.trim() == '' || valorTaxa2.trim() == '00,00') {
        swal({
          title: 'Taxa Administrativa',
          text: 'Favor informar a Taxa Administrativa do prospect',
          type: 'error',
          closeOnConfirm: true
        });
        return;
      }
    }

    this.salvando = true;

    if (this.valortaxa) {
      this.prospect.valorTaxa = $(this.valortaxa.nativeElement).val().toString();
    }

    this.prospect.valorTaxa2 = $(this.valortaxa2.nativeElement).val().toString();

    let me = this;
    let previousWindowKeyDown = window.onkeydown;

    let dados: any = this.prospect;
    dados.recnoProspect = this.recnoProspect;

    dados.observacoes = this.observacoes;

    swal({
      title: 'Prospect',
      text: 'Deseja confirmar o prospect?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    }, function () {
      window.onkeydown = previousWindowKeyDown;

      me.prospectService.att(dados)
        .subscribe(
          response => {
            if (response.success) {
              swal({
                title: 'Prospect',
                text: 'Prospect atualizado com sucesso.',
                type: 'success',
                closeOnConfirm: true
              }, function () {
                me.router.navigate(['prospect']);
              });
            } else {
              swal({
                title: 'Prospect',
                text: response.message,
                type: 'error',
                closeOnConfirm: true
              });

              me.salvando = false;
            }
          }
        );
    });
  }

  removerAcentos(s) {
    const map = {'â': 'a', 'Â': 'A', 'à': 'a', 'À': 'A', 'á': 'a', 'Á': 'A', 'ã': 'a', 'Ã': 'A',
      'ê': 'e', 'Ê': 'E', 'è': 'e', 'È': 'E', 'é': 'e', 'É': 'E',
      'î': 'i', 'Î': 'I', 'ì': 'i', 'Ì': 'I', 'í': 'i', 'Í': 'I',
      'õ': 'o', 'Õ': 'O', 'ô': 'o', 'Ô': 'O', 'ò': 'o', 'Ò': 'O', 'ó': 'o', 'Ó': 'O',
      'ü': 'u', 'Ü': 'U', 'û': 'u', 'Û': 'U', 'ú': 'u', 'Ú': 'U', 'ù': 'u', 'Ù': 'U',
      'ç': 'c', 'Ç': 'C'};
    return s.replace(/[\W\[\] ]/g, function(a) {return map[a] || a});
  }

  adicionarItem(): void {
    this.obsNova = {
      ZZT_DTINC: '',
      ZZT_HRINC: '',
      ZZT_OBS: '',
      ZZT_DTRET: '',
      addStatus: true,
      delStatus: false,
      mostrar: true
    };

    let today: any = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;

    let yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    today = '' + yyyy + mm + dd;

    this.obsNova.ZZT_DTINC = today;

    this.initMask();
  }

  addObs(): void {
    this.obsNova.mostrar = false;

    if (this.obsNova.ZZT_DTRET.trim() != '') {
      let dataRet = this.obsNova.ZZT_DTRET;

      this.obsNova.ZZT_DTRET = dataRet.substr(6, 4) + dataRet.substr(3, 2) + dataRet.substr(0, 2);
    }

    this.obsNova.ZZT_OBS = this.obsNova.ZZT_OBS.toUpperCase();
    this.observacoes.push(this.obsNova);
  }

  deletarItem(index): void {
    let me = this;
    let previousWindowKeyDown = window.onkeydown;

    swal({
      title: 'Prospect',
      text: 'Deseja remover a observação?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      closeOnConfirm: true
    }, function () {
      window.onkeydown = previousWindowKeyDown;


      if ( me.observacoes[index].hasOwnProperty('R_E_C_N_O_') ) {
        me.observacoes[index].delStatus = true;
      } else {
        me.observacoes.splice(index, 1);
      }

    });
  }

  getShowRow(index): boolean {
    if ( this.observacoes[index].hasOwnProperty('delStatus') ) {
      if (this.observacoes[index].delStatus == true) {
        return true;
      }
    } else {
      return false;
    }
  }
}
