import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavbarComponent} from './navbar/navbar.component';
import {CepPipe} from './pipes/cep.pipe';
import {CgcPipe} from './pipes/cgc.pipe';
import {DateBrPipe} from './pipes/datebr.pipe';
import {FoneBrPipe} from './pipes/fonebr.pipe';
import {NumberPipe} from './pipes/number.pipe';
import {SubstringPipe} from './pipes/substring.pipe';
import {MenuComponent} from './menu/menu.component';
import {FooterComponent} from './footer/footer.component';
import {RouterModule} from '@angular/router';
import {CpfPipe} from './pipes/cpf.pipe';
import {CnsPipe} from './pipes/cns.pipe';
import {ModalModule} from 'ngx-bootstrap';
import {FilterMedicosPipe} from './pipes/filter-medicos.pipe';
import {HoraPipe} from './pipes/hora.pipe';
import {BlockUIModule} from 'ng-block-ui';
import {Number2Pipe} from './pipes/number2.pipe';
import {CoreModule} from '../core.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreModule
  ],
  declarations: [
    NavbarComponent,
    MenuComponent,
    FooterComponent,
    CpfPipe,
    CnsPipe,
    CepPipe,
    CgcPipe,
    DateBrPipe,
    HoraPipe,
    FoneBrPipe,
    NumberPipe,
    Number2Pipe,
    SubstringPipe,
    FilterMedicosPipe
  ],
  exports: [
    NavbarComponent,
    MenuComponent,
    FooterComponent,
    CnsPipe,
    CepPipe,
    CpfPipe,
    CgcPipe,
    DateBrPipe,
    HoraPipe,
    FoneBrPipe,
    NumberPipe,
    Number2Pipe,
    SubstringPipe,
    ModalModule,
    FilterMedicosPipe,
    BlockUIModule,
    CoreModule
  ]
})
export class SharedModule {
}
