import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'cep'
})
export class CepPipe implements PipeTransform {
  transform(value: string, args: any[]) {
    value = value.trim();
    if (value && value.length >= 8) {

      return value.substring(0, 2) + '.' +
        value.substring(2, 5) + '-' +
        value.substring(5);

    }

    return '';
  }
}
