import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CredenciadosService} from '../../services/credenciados.service';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';

declare let Metronic: any;
declare let Layout: any;

@Component({
  selector: 'app-credenciados',
  templateUrl: './credenciados.html'
})

export class CredenciadosComponent implements OnInit {
  carregado = false;

  dados = [];

  nomeUser: any;

  @ViewChild('filtrosAbrir', {static: false}) filtrosAbrir: ElementRef;
  @ViewChild('tabelaImprimir', {static: false}) tabelaImprimir: ElementRef;

  credService: any;

  constructor(private credenciadoService: CredenciadosService,
              private router: Router,
              userService: UserService) {
    this.credService = this.credenciadoService;
    this.nomeUser = userService.usuario.nome.trim();
  }

  ngOnInit() {
    this.initMetronic();
    this.inicio(true);
  }

  initMetronic(): void {
    Metronic.init();
    Layout.init();

    let body = 'body';
    $(body).removeClass('login');
    $(body).addClass('page-header-fixed page-sidebar-closed-hide-logo page-container-bg-solid page-content-white page-md page-sidebar-closed');
  }

  abrirFiltros(): void {
    $(this.filtrosAbrir.nativeElement).collapse('toggle');
  }

  inicio(primeiro?: boolean): void {
    if (!this.carregado && !primeiro) {
      return;
    }

    this.carregado = false;

    this.credService.get(this.credService.filtro)
      .subscribe(
        response => {
          this.dados = response.dados;
          this.carregado = true;

          this.credService.filtro.paginaFinal = response.paginaFinal;

          setTimeout(() => {
            $('.maskData').mask('00/00/0000', {placeholder: '__/__/____'});
          }, 1);
        }
      );
  }

  paginacaoDireto(): any {
    this.inicio();
  }

  paginacaoPrimeiro(): any {
    this.credService.filtro.paginaAtual = 1;
    this.inicio();
  }

  paginacaoVoltar(): any {
    this.credService.filtro.paginaAtual -= 1;
    this.inicio();
  }

  paginacaoAvancar(): any {
    this.credService.filtro.paginaAtual += 1;
    this.inicio();
  }

  paginacaoUltimo(): any {
    this.credService.filtro.paginaAtual = this.credService.filtro.paginaFinal;
    this.inicio();
  }

  visualizar(recno): void {
    this.router.navigate(['credenciar/' + recno]);
  }

  pagamento(codigo): void {
    let previousWindowKeyDown = window.onkeydown;
    let me = this;

    swal({
      title: 'Confirmar pagamento',
      text: 'Deseja confirmar o pagamento?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    }, function () {
      window.onkeydown = previousWindowKeyDown;

      me.credService.confirmaPagamento({credenciador: me.nomeUser, codigo: codigo}).subscribe(
        response => {
          if (response.success) {
            swal({
              title: 'Credenciado',
              text: 'Confirmação de pagamento efetuada com sucesso.',
              type: 'success',
              closeOnConfirm: true
            }, function () {
              me.ngOnInit();
            });
          } else {
            swal({
              title: 'Credenciado',
              text: response.message,
              type: 'error',
              closeOnConfirm: true
            });
          }
        }
      );
    });
  }

  excel(): void {
    let dt = new Date();
    let day = dt.getDate();
    let month = dt.getMonth() + 1;
    let year = dt.getFullYear();
    let hour = dt.getHours();
    let mins = dt.getMinutes();
    let postfix = day + '.' + month + '.' + year + '_' + hour + '.' + mins;

    let a: any = document.createElement('a');

    let data_type = 'data:application/vnd.ms-excel';
    let table_div = this.tabelaImprimir.nativeElement;

    let table_html = table_div.outerHTML.replace(/ /g, '%20');
    table_html = table_html.replace(/%20%20/g, '');

    table_html = table_html.replace(/ã/g, 'a');
    table_html = table_html.replace(/Ã/g, 'A');
    table_html = table_html.replace(/á/g, 'a');
    table_html = table_html.replace(/Á/g, 'A');
    table_html = table_html.replace(/à/g, 'a');
    table_html = table_html.replace(/À/g, 'A');
    table_html = table_html.replace(/ä/g, 'a');
    table_html = table_html.replace(/Ä/g, 'A');
    table_html = table_html.replace(/â/g, 'a');
    table_html = table_html.replace(/Â/g, 'A');

    table_html = table_html.replace(/é/g, 'e');
    table_html = table_html.replace(/É/g, 'E');
    table_html = table_html.replace(/è/g, 'e');
    table_html = table_html.replace(/È/g, 'E');
    table_html = table_html.replace(/ê/g, 'e');
    table_html = table_html.replace(/Ê/g, 'E');
    table_html = table_html.replace(/ë/g, 'e');
    table_html = table_html.replace(/Ë/g, 'E');

    table_html = table_html.replace(/í/g, 'i');
    table_html = table_html.replace(/Í/g, 'I');
    table_html = table_html.replace(/ì/g, 'i');
    table_html = table_html.replace(/Ì/g, 'I');
    table_html = table_html.replace(/î/g, 'i');
    table_html = table_html.replace(/Î/g, 'I');
    table_html = table_html.replace(/ï/g, 'i');
    table_html = table_html.replace(/Ï/g, 'I');

    table_html = table_html.replace(/õ/g, 'o');
    table_html = table_html.replace(/Ô/g, 'O');
    table_html = table_html.replace(/ó/g, 'o');
    table_html = table_html.replace(/Ó/g, 'O');
    table_html = table_html.replace(/ò/g, 'o');
    table_html = table_html.replace(/Ò/g, 'O');
    table_html = table_html.replace(/ô/g, 'o');
    table_html = table_html.replace(/Ô/g, 'O');
    table_html = table_html.replace(/ö/g, 'o');
    table_html = table_html.replace(/Ö/g, 'O');

    table_html = table_html.replace(/ú/g, 'u');
    table_html = table_html.replace(/Ú/g, 'U');
    table_html = table_html.replace(/ù/g, 'u');
    table_html = table_html.replace(/Ù/g, 'U');
    table_html = table_html.replace(/û/g, 'u');
    table_html = table_html.replace(/Û/g, 'U');
    table_html = table_html.replace(/ü/g, 'u');
    table_html = table_html.replace(/Ü/g, 'U');

    table_html = table_html.replace(/ç/g, 'c');
    table_html = table_html.replace(/Ç/g, 'C');
    a.href = data_type + ', ' + table_html;
    a.download = 'exported_table_' + postfix + '.xls';
    a.click();
  }
}
