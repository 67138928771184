import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CredenciadosService} from '../../../services/credenciados.service';

declare let Metronic: any;
declare let Layout: any;

@Component({
  selector: 'app-pagamentos',
  templateUrl: './pagamentos.html'
})

export class PagamentosComponent implements OnInit {
  carregado = false;

  dados = [];

  filtro = {
    codtn: '',
    fantasia: '',
    data: '',
    vencimento: '',
    valor: '',
    paginaAtual: 1,
    paginaFinal: 1,
    limite: '10'
  };

  @ViewChild('filtrosAbrir', {static: false}) filtrosAbrir: ElementRef;

  constructor(private credenciadoService: CredenciadosService) { }

  ngOnInit(): any {
    this.initMetronic();
    this.inicio(true);
  }

  initMetronic(): void {
    Metronic.init();
    Layout.init();

    let body = 'body';
    $(body).removeClass('login');
    $(body).addClass('page-header-fixed page-sidebar-closed-hide-logo page-container-bg-solid page-content-white page-md page-sidebar-closed');
  }

  abrirFiltros(): void {
    $(this.filtrosAbrir.nativeElement).collapse('toggle');
  }

  inicio(primeiro?: boolean): void {
    if (!this.carregado && !primeiro) {
      return;
    }

    this.carregado = false;

    this.credenciadoService.getPagamentos(this.filtro)
      .subscribe(
        response => {
          this.dados = response.dados;
          this.carregado = true;

          this.filtro.paginaFinal = response.paginaFinal;
        }
      );
  }

  paginacaoDireto(): any {
    this.inicio();
  }

  paginacaoPrimeiro(): any {
    this.filtro.paginaAtual = 1;
    this.inicio();
  }

  paginacaoVoltar(): any {
    this.filtro.paginaAtual -= 1;
    this.inicio();
  }

  paginacaoAvancar(): any {
    this.filtro.paginaAtual += 1;
    this.inicio();
  }

  paginacaoUltimo(): any {
    this.filtro.paginaAtual = this.filtro.paginaFinal;
    this.inicio();
  }
}
