import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {LogoutComponent} from './logout';
import {ModalModule, TooltipModule} from 'ngx-bootstrap';
import {ServiceMain} from './services/service-main.service';
import {UserService} from './services/user.service';
import {AuthGuard} from './services/auth-guard.service';
import {AuthLogin} from './services/auth-login.service';
import {LoginComponent} from './pages/login/login';
import {RouterOutletComponent} from './router-outlet.component';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {InicioComponent} from './pages/inicio/inicio.component';
import {SenhaComponent} from './pages/senha/senha.component';
import {ProspectComponent} from './pages/prospect/prospect.component';
import {ProspectService} from './services/prospect.service';
import {OSComponent} from './pages/os/os.component';
import {OsService} from './services/os.service';
import {CredenciadosComponent} from './pages/credenciamentos/credenciados.component';
import {CredenciadosService} from './services/credenciados.service';
import {PagamentosComponent} from './pages/credenciamentos/pagamento/pagamentos.component';
import {CredenciarComponent} from './pages/credenciamentos/credenciar/credenciar.component';
import {CorreiosService} from './services/correios.services';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ProspectEspComponent} from './pages/prospect/prospectEsp/prospectEsp.component';
import {OSEspComponent} from './pages/os/osesp/osesp.component';
import {HttpClientModule} from '@angular/common/http';
import {UsuariosService} from './services/usuarios.service';
import {JwBootstrapSwitchNg2Module} from 'jw-bootstrap-switch-ng2';
import {PersistenceService} from 'angular-persistence';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    SharedModule,
    BrowserAnimationsModule,
    JwBootstrapSwitchNg2Module
  ],
  declarations: [
    RouterOutletComponent,
    LoginComponent,
    LogoutComponent,

    InicioComponent,
    ProspectComponent,
    ProspectEspComponent,

    SenhaComponent,
    OSComponent,
    CredenciadosComponent,
    PagamentosComponent,
    CredenciarComponent,

    OSEspComponent
  ],
  bootstrap: [RouterOutletComponent],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    UserService,
    ServiceMain,
    AuthGuard,
    AuthLogin,

    OsService,
    ProspectService,
    CredenciadosService,

    CorreiosService,
    UsuariosService,
    PersistenceService
  ]
})

export class AppModule {
}
