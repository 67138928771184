import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatFone'
})
export class FoneBrPipe implements PipeTransform {
  transform(fone: any, args?: any): any {
    let dateformat;

    if (fone.length > 10) {
      args = '(XX) XXXXX-XXXX';
    } else if (fone.length == 8) {
      args = 'XXXX-XXXX';
    } else {
      args = '(XX) XXXX-XXXX';
    }

    if (args == '(XX) XXXX-XXXX') {
      dateformat = '(' +
        fone.substring(0, 2) + ') ' +
        fone.substring(2, 6) + '-' +
        fone.substring(6, 10);


      return dateformat;
    }

    if (args == '(XX) XXXXX-XXXX') {
      dateformat = '(' +
        fone.substring(0, 2) + ') ' +
        fone.substring(2, 7) + '-' +
        fone.substring(6, 11);


      return dateformat;
    }


    if (args == 'XXXX-XXXX') {
      dateformat = fone.substring(0, 4) + '-' +
        fone.substring(4, 8);

      return dateformat;
    }
  }
}
