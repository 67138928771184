import {Observable} from 'rxjs/Observable';
import 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {PersistenceService, StorageType} from 'angular-persistence';

@Injectable()
export class ServiceMain {
  public pbc = false;

  constructor(private _httpp: HttpClient,
              private routerrr: Router,
              private persistenceeService: PersistenceService) { }

  protected getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Auth-Token-Update': this.persistenceeService.get('token', StorageType.LOCAL)
    });
  }

  protected executaGET(filtros: any, url: string, gravaToken = true): Observable<any> {
    let options: any = {
      headers: this.getHeaders(),
      params: filtros,
      observe: 'response'
    };

    return this._httpp.get(environment.serverUrl + url,
      options
    ).pipe(
      map((res: any) => {
        if (gravaToken) {
          this.persistenceeService.set('token', res.headers.get('X-Auth-Token-Update'), {type: StorageType.LOCAL});
        }

        return res.body;
      })
    );
  }

  protected executaGETID(id: any, url: string): Observable<any> {
    let options: any = {
      headers: this.getHeaders(),
      observe: 'response'
    };

    return this._httpp.get(environment.serverUrl + url + '/' + id,
      options
    ).pipe(
      map((res: any) => {
        this.persistenceeService.set('token', res.headers.get('X-Auth-Token-Update'), {type: StorageType.LOCAL});

        return res.body;
      })
    );
  }

  protected executaPOST(dados: any, url: string): Observable<any> {
    let body = JSON.stringify(dados);

    let options: any = {
      headers: this.getHeaders(),
      observe: 'response'
    };

    return this._httpp.post(environment.serverUrl + url, body,
      options
    ).pipe(
      map((res: any) => {
        this.persistenceeService.set('token', res.headers.get('X-Auth-Token-Update'), {type: StorageType.LOCAL});

        return res.body;
      })
    );
  }

  protected executaPUT(dados: any, url: string): Observable<any> {
    let body = JSON.stringify(dados);

    let options: any = {
      headers: this.getHeaders(),
      observe: 'response'
    };

    return this._httpp.put(environment.serverUrl + url, body,
      options
    ).pipe(
      map((res: any) => {
        this.persistenceeService.set('token', res.headers.get('X-Auth-Token-Update'), {type: StorageType.LOCAL});

        return res.body;
      })
    );
  }

  protected executaDELETE(id: any, url: string): Observable<any> {
    let options: any = {
      headers: this.getHeaders(),
      observe: 'response'
    };

    return this._httpp.delete(environment.serverUrl + url + '/' + id,
      options
    ).pipe(
      map((res: any) => {
        this.persistenceeService.set('token', res.headers.get('X-Auth-Token-Update'), {type: StorageType.LOCAL});

        return res.body;
      })
    );
  }

  protected executaDELETEParams(params: any, url: string): Observable<any> {
    let options: any = {
      headers: this.getHeaders(),
      params: params,
      observe: 'response'
    };

    return this._httpp.delete(environment.serverUrl + url,
      options
    ).pipe(
      map((res: any) => {
        this.persistenceeService.set('token', res.headers.get('X-Auth-Token-Update'), {type: StorageType.LOCAL});

        return res.body;
      })
    );
  }
}
