import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'substring'
})
export class SubstringPipe implements PipeTransform {
  transform(value: string, arg1: any, arg2: any) {
    if (arg1 != undefined) {
      if (arg2 != undefined && arg2 > 0)
        return value.substr(arg1, arg2);

      return value.substr(arg1);
    }

    return value;
  }
}
