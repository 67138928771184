import {Observable} from 'rxjs/Observable';
import 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceMain} from './service-main.service';
import {Router} from '@angular/router';
import {PersistenceService} from 'angular-persistence';

@Injectable()
export class UsuariosService extends ServiceMain {
  constructor(private _http: HttpClient,
              private routers: Router,
              private persistenceService: PersistenceService) {
    super(_http, routers, persistenceService);
  }
  attSenha(dados: any): Observable<any> {
    return this.executaPOST(dados, 'user/alterarsenha');
  }

  validaSenha(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'user/validasenha');
  }
}
