import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-router-outlet',
  template: `
      <router-outlet></router-outlet>
    `
})

export class RouterOutletComponent {
  constructor(private router: Router) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        let body = 'body';

        if (val.url != '/' && val.url != '/sesi') {
          $(body).removeClass('login');
          $(body).addClass('page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo');
        } else {
          $(body).removeAttr('class');
          $(body).addClass('login');
        }
      }
    });
  }
}
