import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.html'
})
export class MenuComponent {
  marcelo = false;

  router: Router;

  constructor(private routerr: Router, private userService: UserService) {
    this.router = routerr;

    if (this.userService.usuario.codigo == '001778') {
      this.marcelo = true;
    }
  }
}
