import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, retry} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {PersistenceService, StorageType} from 'angular-persistence';

@Injectable()
export class UserService {
  usuario: any;

  token: string;

  constructor(private _login: HttpClient,
              private persistenceService: PersistenceService) {
    this.usuario = this.persistenceService.get('usuario', StorageType.LOCAL);
    this.token = this.persistenceService.get('token', StorageType.LOCAL);
  }

  login(login: { usuario: string, senha: string }, lembrarUsuario: boolean): Observable<any> {
    const body = JSON.stringify(login);

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._login.post(environment.serverUrl + 'login/autenticacao', body,
      options
    ).pipe(
      retry(3),
      catchError(this.handleError),
      map((res: any) => {
        if (res.success) {
          this.usuario = res.usuario;
          this.token = res.token;

          this.persistenceService.set('token', res.token, {type: StorageType.LOCAL});
          this.persistenceService.set('usuario', res.usuario, {type: StorageType.LOCAL});

          let salvar = '';

          if (lembrarUsuario) {
            salvar = res.usuario.login;
          }

          this.persistenceService.set('login', salvar, {type: StorageType.LOCAL});
        }

        return res;
      })
    );
  }

  logout(): void {
    let login = this.persistenceService.get('login', StorageType.LOCAL);
    this.persistenceService.removeAll(StorageType.LOCAL);
    this.persistenceService.set('login', login, {type: StorageType.LOCAL});
  }

  protected handleError(error: any): any {
    swal({
      title: 'Ocorreu um problema!',
      text: error._body + ' - Por favor, entre em contato com o administrador.',
      type: 'error',
      closeOnConfirm: true
    });

    return of();
  }
}
