import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.html'
})
export class NavbarComponent {
  nome: string;

  constructor(private userService: UserService, private router: Router) {
    this.nome = this.userService.usuario.nome;
  }

  logoff(): void {
    this.router.navigate(['logout']);
  }
}
