import {Injectable} from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import {PersistenceService, StorageType} from 'angular-persistence';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
              private persistenceService: PersistenceService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    if (this.persistenceService.get('usuario', StorageType.LOCAL)) {
      return true;
    }

    // not logged in so redirect to login page
    this.router.navigate(['logout']);
    return false;
  }
}
