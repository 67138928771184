import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './pages/login/login';
import {AuthLogin} from './services/auth-login.service';
import {LogoutComponent} from './logout';
import {AuthGuard} from './services/auth-guard.service';
import {InicioComponent} from './pages/inicio/inicio.component';
import {SenhaComponent} from './pages/senha/senha.component';
import {ProspectComponent} from './pages/prospect/prospect.component';
import {OSComponent} from './pages/os/os.component';
import {CredenciadosComponent} from './pages/credenciamentos/credenciados.component';
import {PagamentosComponent} from './pages/credenciamentos/pagamento/pagamentos.component';
import {CredenciarComponent} from './pages/credenciamentos/credenciar/credenciar.component';
import {ProspectEspComponent} from './pages/prospect/prospectEsp/prospectEsp.component';
import {OSEspComponent} from './pages/os/osesp/osesp.component';

export const appRoutes: Routes = [
  {path: '', component: LoginComponent, canActivate: [AuthLogin]},
  {path: 'inicio', component: InicioComponent, canActivate: [AuthGuard]},
  {path: 'prospect', component: ProspectComponent, canActivate: [AuthGuard]},
  {path: 'prospect/:codigo', component: ProspectEspComponent, canActivate: [AuthGuard]},
  {path: 'os', component: OSComponent, canActivate: [AuthGuard]},
  {path: 'os/:codigo', component: OSEspComponent, canActivate: [AuthGuard]},
  {path: 'credenciados', component: CredenciadosComponent, canActivate: [AuthGuard]},
  {path: 'pagamentos', component: PagamentosComponent, canActivate: [AuthGuard]},
  {path: 'credenciar/:codigo', component: CredenciarComponent, canActivate: [AuthGuard]},
  {path: 'senha', component: SenhaComponent, canActivate: [AuthGuard]},
  {path: 'logout', component: LogoutComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
