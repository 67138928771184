import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'number2'
})
export class Number2Pipe implements PipeTransform {
  transform(value: any, tam?: number) {
    let casas = 2;

    if (tam || tam == 0) {
      casas = tam;
    }

    value = value.replace(',', '.');

    let n = value,
      c = casas,
      d = ',',
      t = '.',
      s = n < 0 ? '-' : '',
      i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + '',
      j = i.length;

    j = (j > 3) ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - parseInt(i)).toFixed(c).slice(2) : '');
  }
}
