import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'hora'
})
export class HoraPipe implements PipeTransform {
  transform(date: any, args: any): any {
    return date.substring(11, 16);
  }
}
