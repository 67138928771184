import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {OsService} from '../../services/os.service';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {ModalDirective} from 'ngx-bootstrap';

declare let Metronic: any;
declare let Layout: any;

@Component({
  selector: 'app-os',
  templateUrl: './os.html'
})

export class OSComponent implements OnInit {
  carregado = false;

  dados = [];
  zObservacao = '';
  codTel = 0;
  filtro = {
    nome: '',
    ativas: true,
    encerradas: false,
    os: '',
    codtn: '',
    nomefantasia: '',
    emissao: '',
    paginaAtual: 1,
    paginaFinal: 1,
    limite: '10'
  };

  @ViewChild('modalObservation', {static: true}) modalObservation: ModalDirective;
  @ViewChild('filtrosAbrir', {static: false}) filtrosAbrir: ElementRef;

  constructor(private osService: OsService,
              private userService: UserService,
              private router: Router) {
    this.filtro.nome = userService.usuario.nome.trim();
  }

  ngOnInit(): any {
    this.initMetronic();
    this.inicio(true);
  }

  initMetronic(): void {
    Metronic.init();
    Layout.init();

    let body = 'body';
    $(body).removeClass('login');
    $(body).addClass('page-header-fixed page-sidebar-closed-hide-logo page-container-bg-solid page-content-white page-md page-sidebar-closed');
  }

  abrirFiltros(): void {
    $(this.filtrosAbrir.nativeElement).collapse('toggle');

    setTimeout(function () {
      let maskData = '.mascaraData';

      $(maskData).unmask();
      $(maskData).mask('00/00/0000', {placeholder: '__/__/____'});
    }, 1);
  }

  inicio(primeiro?: boolean): void {
    if (!this.carregado && !primeiro) {
      return;
    }

    this.carregado = false;

    this.osService.get(this.filtro)
      .subscribe(
        response => {
          this.dados = response.dados;
          this.carregado = true;

          this.filtro.paginaFinal = response.paginaFinal;
        }
      );
  }

  paginacaoDireto(): any {
    this.inicio();
  }

  paginacaoPrimeiro(): any {
    this.filtro.paginaAtual = 1;
    this.inicio();
  }

  paginacaoVoltar(): any {
    this.filtro.paginaAtual -= 1;
    this.inicio();
  }

  paginacaoAvancar(): any {
    this.filtro.paginaAtual += 1;
    this.inicio();
  }

  paginacaoUltimo(): any {
    this.filtro.paginaAtual = this.filtro.paginaFinal;
    this.inicio();
  }

  editar(numos): void {
    this.router.navigate(['os/' + numos]);
  }

  showmodal(): void {
    $('#meuModal').on('shown.bs.modal', function () {
      $('#meuInput').trigger('focus')
    });
  }

  ativar(zcred): void {
    let me = this;
    let previousWindowKeyDown = window.onkeydown;

    if (this.zObservacao == '') {
      swal({
        title: 'Ativar credenciado',
        text: 'Você deve incluir um motivo para ativação.',
        type: 'error',
        closeOnConfirm: true
      });
    } else {
       let date = new Date();
       this.zObservacao = 'Status do PJ alterado para ATIVO. ' +
         'Data: ' + date.toLocaleDateString() +
         ', Hora: ' + date.toLocaleTimeString('pt-BR') +
         ' Motivo: ' + this.zObservacao +
         '  --> Alterado por: ' + this.filtro.nome;
    }

    me.modalObservation.hide();

    swal({
      title: 'Ativar credenciado',
      text: 'Deseja realmente ativar esse credenciado?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    }, function () {
      window.onkeydown = previousWindowKeyDown;

      me.osService.ativarCred({codTel: zcred, zObs: me.zObservacao})
        .subscribe(
          response => {
            if (response.success) {
              swal({
                title: 'Ativar credenciado',
                text: 'Credenciado ativado com sucesso.',
                type: 'success',
                closeOnConfirm: true
              }, function () {
                me.inicio();
              });
            } else {
              swal({
                title: 'Ativar credenciado',
                text: response.message,
                type: 'error',
                closeOnConfirm: true
              });
            }
          }
        );
    });
  }

  bloquear(zcred): void {
    let me = this;
    let previousWindowKeyDown = window.onkeydown;

    swal({
      title: 'Bloquear credenciado',
      text: 'Deseja realmente bloquear esse credenciado?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    }, function () {
      window.onkeydown = previousWindowKeyDown;

      me.osService.bloquearCred({codTel: zcred})
        .subscribe(
          response => {
            if (response.success) {
              swal({
                title: 'Bloquear credenciado',
                text: 'Credenciado bloqueado com sucesso.',
                type: 'success',
                closeOnConfirm: true
              }, function () {
                me.inicio();
              });
            } else {
              swal({
                title: 'Bloquear credenciado',
                text: response.message,
                type: 'error',
                closeOnConfirm: true
              });
            }
          }
        );
    });
  }
}
