import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CredenciadosService} from '../../../services/credenciados.service';
import {ModalDirective} from 'ngx-bootstrap';
import {CorreiosService} from '../../../services/correios.services';
import {ActivatedRoute, Router} from '@angular/router';

declare let Metronic: any;
declare let Layout: any;

@Component({
  selector: 'app-credenciar',
  templateUrl: './credenciar.html',
  styleUrls: ['./credenciar.css']
})

export class CredenciarComponent implements OnInit {
  carregado = true;
  carregadoRamo = true;
  carregadoCorreio = true;
  carregadoCorreioCorres = true;

  salvando = false;

  segmentos = [];
  ramo = [];
  estados = [];
  bancos = [];
  cidades = [];
  especialidades = [];
  bandeiras = [];

  cidadesCorres = [];

  formError = '';


  recnoCred = '';
  AddRowHDF = [];

  credenciado = {
    rede: '',

    pix: 'S',
    taxaConect: '',

    razao: '',
    fantasia: '',
    tipoCgc: '',
    cgc: '',
    inscricao: '',
    tipoEst: 'M',
    segmento: '',
    ramo: '',
    telefone: '',
    fax: '',
    contato: '',
    email: '',
    socio1nome: '',
    socio1cpf: '',
    socio2nome: '',
    socio2cpf: '',
    responsavel: '',
    valorConsulta: '',
    especialidades: [],

    cep: '',
    estado: '',
    endereco: '',
    bairro: '',
    cidade: '',
    complemento: '',
    endCorres: 'I',

    cepCorres: '',
    estadoCorres: '',
    enderecoCorres: '',
    bairroCorres: '',
    cidadeCorres: '',
    complementoCorres: '',

    banco: '',
    agencia: '',
    conta: '',
    docted: 'S',
    tipoConta: '1',
    localPgto: 'E',
    codCentralizadora: '',

    fechamento: 'Q',
    diaFechamento: '',
    prazoPagamento: '',
    valorTaxa2: '',
    taxaAdm: false,
    pgtoDinheiro: false,
    valorTaxa: '',
    observacao: '',

    plusfrota: false,
    idtipocred: true,
    ura: false,
    web: false,
    pos: false,
    tef: false,
    pago: false,
    cielo: false,
    getnet: false,
    stone: false,
    first: false,
    tecRede:false,

    obsTecnologiaPos: '',
    tecPOS: '',
    operadoraPOS: '',
    numChip: '',
    serial: '',
    patrimonio: '',
    aluguelPos: 'N',
    valorPOS: '',
    carencia: '',

    obsTecnologiaTefContato: '',
    obsTecnologiaTefTelefone: '',

    mensalidadeWeb: '',
    carenciaWeb: '',

    mensalidadeTef: '',
    carenciaTef: '',

    mensalidadeCielo: '',
    carenciaCielo: '',
    ec: '',

    mensalidadeRede: '',
    carenciaRede: '',
    pv: '',

    mensalidadeGetnet: '',
    carenciaGetnet: '',

    mensalidadeStone: '',
    carenciaStone: '',

    selectFirst: 'BIN',
    mensalidadeFirst: '',
    carenciaFirst: '',

    bandeira: '',
    certAmb: '',
    dataVencCertAmb: '',
    ANP : '',
    DESPANP: '',
    dataVencANP : '',
    LATIGPS : '',
    LONGGPS : '',
    AddRowHDF: [],

    postoUrbano: false,
    postoRodovia: false,
    bombaInterna: false,

    autoPecas: false,
    autoEletrica: false,
    borracharia: false,
    conveniencia: false,
    estacionamento: false,
    geomBalanc: false,
    guincho: false,
    hotel: false,
    lavacao: false,
    restLanch: false,
    trocaOleoFiltro: false,

    gasolinaComum: false,
    gasolinaAditivada: false,
    gasolinaEspecial: false,
    diesel: false,
    dieselS10: false,
    dieselS10Aditivado: false,
    biodiesel: false,
    gnv: false,
    etanol: false,
    etanolAditivado: false,
    arla32: false,
    querosene: false,

    oleoMineral: false,
    oleoSintetico: false,
    oleoSemiSintetico: false,
    oleoCaixa: false,
    filtroOleo: false,
    filtroCombust: false,
    filtroAr: false,
    filtroArCondicionado: false,
    lubrificacao: false,

    aditivoRadiador: false,
    aditivoCombustivel: false,
    aditivoParaBrisa: false,
    fluidoFreio: false,
    extintor: false,
    palheta: false,
    consertoPneu: false,
    lavacaoCompleta: false,
    lavacaoExpressa: false,
    polimento: false,
    enceramento: false,
    manutencao: false,

    frotaLeve: false,
    frotaPesada: false,
    maquinasEquipamentos: false,

    oficinaEspecializada: false,
    oficinaMultimarcas: false,
    pecasGeral: false,
    eletricaGeral: false,
    mecanicaGeral: false,
    pBorracharia: false,
    iluminacaoSinalizacao: false,
    motor: false,
    funilariaPintura: false,
    vidrosPortas: false,
    cambio: false,
    pGuincho: false,
    gerencEletronico: false,
    SuspencaoFreios: false,
    pLavacao: false,
    pneusRodas: false,
    arCondicionado: false,
    geometriaBalanc: false,
    injecaoEletronica: false,
    bateria: false,
    trocaOleoFiltros: false,

    Fiat: false,
    GM: false,
    Peugeot: false,
    Ford: false,
    Honda: false,
    Citroen: false,
    Volkswagen: false,
    Renault: false,
    Outras: false,

    valorTaxaPublico: ''
  };

  mostrarConsulta = false;
  captcha = '';

  carregadoCNPJ = true;
  CGCValidado = false;

  @ViewChild('modalReceita', {static: false}) modalReceita: ModalDirective;

  @ViewChild('valortaxa2', {static: false}) valortaxa2: ElementRef;
  @ViewChild('valortaxa', {static: false}) valortaxa: ElementRef;
  @ViewChild('valorTaxaPublico', {static: false}) valorTaxaPublico: ElementRef;

  @ViewChild('socio1cpf', {static: false}) socio1cpf: ElementRef;
  @ViewChild('socio2cpf', {static: false}) socio2cpf: ElementRef;

  @ViewChild('cgc', {static: false}) cgc: ElementRef;
  @ViewChild('valorPOS', {static: false}) valorPOS: ElementRef;

  @ViewChild('valorCIELO', {static: false}) valorCIELO: ElementRef;
  @ViewChild('valorGETNET', {static: false}) valorGETNET: ElementRef;
  @ViewChild('valorWEB', {static: false}) valorWEB: ElementRef;
  @ViewChild('valorTEF', {static: false}) valorTEF: ElementRef;
  @ViewChild('valorSTONE', {static: false}) valorSTONE: ElementRef;
  @ViewChild('valorFIRST', {static: false}) valorFIRST: ElementRef;
  @ViewChild('valorREDE', {static: false}) valorREDE: ElementRef;

  @ViewChild('especialidade', {static: false}) especialidade: ElementRef;
  @ViewChild('valorconsulta', {static: false}) valorconsulta: ElementRef;

  @ViewChild('captchaDigitada', {static: false}) captchaDigitada: ElementRef;

  constructor(private credenciadoService: CredenciadosService,
              private correiosService: CorreiosService,
              private router: Router,
              private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.resetar();

      if (params['codigo'] && params['codigo'] != 'novo') {
        this.recnoCred = params['codigo'];

        this.carregado = false;

        this.credenciadoService.getEsp({codigo: this.recnoCred})
          .subscribe(
            response => {
              this.credenciado = response.credenciado;
              this.AddRowHDF = this.credenciado.AddRowHDF;

              if (this.credenciado.prazoPagamento != '') {
                this.credenciado.prazoPagamento = parseInt(this.credenciado.prazoPagamento).toString();
              }

              this.mudarTipoCgc(this.credenciado.tipoCgc);

              if (this.credenciado.estado != '') {
                this.getCidades(this.credenciado.estado, true);
              }

              if (this.credenciado.estadoCorres != '') {
                this.getCidadesCorres(this.credenciado.estadoCorres, true);
              }

              if (this.credenciado.localPgto.trim() == '') {
                this.credenciado.localPgto = 'E';
              }

              this.credenciado.segmento = this.credenciado.segmento.trim();
              this.credenciado.ramo = this.credenciado.ramo.trim();
              this.credenciado.banco = this.credenciado.banco.trim();

              if (this.credenciado.docted.trim() == '') {
                this.credenciado.docted = 'S';
              }

              if (this.credenciado.fechamento.trim() == '') {
                this.credenciado.fechamento = 'Q';
              }

              this.getCombos();

              this.carregado = true;
              this.CGCValidado = true;
            }
          );
      } else {
        this.getCombos();
      }
    });
  }

  resetar(): void {
    this.carregado = true;
    this.carregadoCorreio = true;
    this.carregadoCorreioCorres = true;

    this.salvando = false;

    this.segmentos = [];
    this.ramo = [];
    this.estados = [];
    this.bancos = [];
    this.cidades = [];
    this.especialidades = [];
    this.bandeiras = [];

    this.cidadesCorres = [];

    this.formError = '';

    this.recnoCred = '';

    this.credenciado = {

      pix: 'S',
      taxaConect: '',

      rede: '',
      razao: '',
      fantasia: '',
      tipoCgc: '',
      cgc: '',
      inscricao: '',
      tipoEst: 'M',
      segmento: '',
      ramo: '',
      telefone: '',
      fax: '',
      contato: '',
      email: '',
      socio1nome: '',
      socio1cpf: '',
      socio2nome: '',
      socio2cpf: '',
      responsavel: '',
      valorConsulta: '',
      especialidades: [],

      cep: '',
      estado: '',
      endereco: '',
      bairro: '',
      cidade: '',
      complemento: '',
      endCorres: 'I',

      cepCorres: '',
      estadoCorres: '',
      enderecoCorres: '',
      bairroCorres: '',
      cidadeCorres: '',
      complementoCorres: '',

      banco: '',
      agencia: '',
      conta: '',
      docted: 'S',
      tipoConta: '1',
      localPgto: 'E',
      codCentralizadora: '',

      fechamento: 'Q',
      diaFechamento: '',
      prazoPagamento: '',
      valorTaxa2: '',
      taxaAdm: false,
      pgtoDinheiro: false,
      valorTaxa: '',
      observacao: '',

      plusfrota: false,
      idtipocred: true,
      ura: false,
      web: false,
      pos: false,
      tef: false,
      pago: false,
      cielo: false,
      getnet: false,
      stone: false,
      first: false,
      tecRede:false,


      obsTecnologiaPos: '',
      tecPOS: '',
      operadoraPOS: '',
      numChip: '',
      serial: '',
      patrimonio: '',
      aluguelPos: 'N',
      valorPOS: '',
      carencia: '',

      obsTecnologiaTefContato: '',
      obsTecnologiaTefTelefone: '',

      mensalidadeWeb: '',
      carenciaWeb: '',

      mensalidadeTef: '',
      carenciaTef: '',

      mensalidadeCielo: '',
      carenciaCielo: '',
      ec: '',

      mensalidadeRede: '',
      carenciaRede: '',
      pv: '',

      mensalidadeGetnet: '',
      carenciaGetnet: '',

      mensalidadeStone: '',
      carenciaStone: '',

      selectFirst: 'BIN',
      mensalidadeFirst: '',
      carenciaFirst: '',

      bandeira: '',
      certAmb: '',
      dataVencCertAmb: '',
      ANP : '',
      DESPANP: '',
      dataVencANP : '',
      LATIGPS : '',
      LONGGPS : '',
      AddRowHDF: [],

      postoUrbano: false,
      postoRodovia: false,
      bombaInterna: false,

      autoPecas: false,
      autoEletrica: false,
      borracharia: false,
      conveniencia: false,
      estacionamento: false,
      geomBalanc: false,
      guincho: false,
      hotel: false,
      lavacao: false,
      restLanch: false,
      trocaOleoFiltro: false,

      gasolinaComum: false,
      gasolinaAditivada: false,
      gasolinaEspecial: false,
      diesel: false,
      dieselS10: false,
      dieselS10Aditivado: false,
      biodiesel: false,
      gnv: false,
      etanol: false,
      etanolAditivado: false,
      arla32: false,
      querosene: false,

      oleoMineral: false,
      oleoSintetico: false,
      oleoSemiSintetico: false,
      oleoCaixa: false,
      filtroOleo: false,
      filtroCombust: false,
      filtroAr: false,
      filtroArCondicionado: false,
      lubrificacao: false,

      aditivoRadiador: false,
      aditivoCombustivel: false,
      aditivoParaBrisa: false,
      fluidoFreio: false,
      extintor: false,
      palheta: false,
      consertoPneu: false,
      lavacaoCompleta: false,
      lavacaoExpressa: false,
      polimento: false,
      enceramento: false,
      manutencao: false,

      frotaLeve: false,
      frotaPesada: false,
      maquinasEquipamentos: false,

      oficinaEspecializada: false,
      oficinaMultimarcas: false,
      pecasGeral: false,
      eletricaGeral: false,
      mecanicaGeral: false,
      pBorracharia: false,
      iluminacaoSinalizacao: false,
      motor: false,
      funilariaPintura: false,
      vidrosPortas: false,
      cambio: false,
      pGuincho: false,
      gerencEletronico: false,
      SuspencaoFreios: false,
      pLavacao: false,
      pneusRodas: false,
      arCondicionado: false,
      geometriaBalanc: false,
      injecaoEletronica: false,
      bateria: false,
      trocaOleoFiltros: false,

      Fiat: false,
      GM: false,
      Peugeot: false,
      Ford: false,
      Honda: false,
      Citroen: false,
      Volkswagen: false,
      Renault: false,
      Outras: false,

      valorTaxaPublico: ''
    };
  }

  getCombos(): void {
    this.credenciadoService.getCombos()
      .subscribe(
        response => {
          this.segmentos = response.segmentos;
          this.ramo = response.ramo;
          this.estados = response.estados;
          this.bancos = response.bancos;
          this.especialidades = response.especialidades;
          this.bandeiras = response.bandeiras;

          if (this.credenciado.especialidades.length > 0) {
            for (let i = 0; i < this.especialidades.length; i++) {
              let procura = this.credenciado.especialidades.find((item: any) => item.Z04_CODESP == this.especialidades[i].Z03_CODIGO);

              if (procura != undefined) {
                this.especialidades[i].selec = 'true';
              }
            }
          }

          this.initMask();
        }
      );
  }

  ngOnInit(): any {
    this.initMetronic();
    this.initMask();
  }

  filterRamo(): any {
    this.carregadoRamo = false;

    this.credenciado.plusfrota = ['000005', '000025'].includes(this.credenciado.segmento);

    this.credenciadoService.getFilterComboRamo({segmento : this.credenciado.segmento})
      .subscribe(
        response => {
          this.ramo = response.ramo;
          this.carregadoRamo = true;
        }
      );
  }

  initMask(): void {
    let me = this;

    setTimeout(function () {
      if (me.especialidade) {
        $(me.especialidade.nativeElement).multiSelect();
      }

      let maskFone = '.maskFone';
      let maskCPF = '.maskCPF';
      let maskCEP = '.maskCEP';
      let maskCNPJ = '.maskCNPJ';
      let maskMoeda = '.maskMoeda';
      let maskData = '.maskData';
      let maskTime = '.maskTime';

      let maskValorA = '.maskValorA';
      let maskValorB = '.maskValorB';

      $(maskFone).unmask();
      $(maskCPF).unmask();
      $(maskCEP).unmask();
      $(maskCNPJ).unmask();
      $(maskMoeda).unmask();
      $(maskData).unmask();

      $(maskFone).mask('(00) 0000-00000');
      $(maskCPF).mask('000.000.000-00', {placeholder: '___.___.___-__'});
      $(maskCEP).mask('00000-000', {placeholder: '_____-__'});
      $(maskCNPJ).mask('00.000.000/0000-00', {placeholder: '__.___.___/____-__'});
      $(maskMoeda).mask('#.##0,00', {reverse: true});
      $(maskData).mask('00/00/0000', {placeholder: '__/__/____'});
      $(maskTime).mask('00:00', {placeholder: '__:__'});

      $(maskValorA).mask('#.##0,00', {reverse: true});
      $(maskValorB).mask('#.##0,00', {reverse: true});

      $('.maskTaxa').mask('#9,00', {reverse: true});
      $('.maskBanco').mask('0A');

      $('.maskAgencia').mask('#', {
        maxlength: false,
        translation: {
          '#': {pattern: /[0-9]/, recursive: true}
        }
      });

      $('.maskConta').mask('#', {
        maxlength: false,
        translation: {
          '#': {pattern: /[0-9- ]/, recursive: true}
        }
      });
    }, 1);
  }

  initMetronic(): void {
    Metronic.init();
    Layout.init();

    let body = 'body';
    $(body).removeClass('login');
    $(body).addClass('page-header-fixed page-sidebar-closed-hide-logo page-container-bg-solid page-content-white page-md page-sidebar-closed');
  }

  mudarTipoCgc(tipo): void {
    let maskCGC = '.maskCGC';
    if (tipo == '1') {
      setTimeout(function () {
        $(maskCGC).unmask();
        $(maskCGC).mask('000.000.000-00', {placeholder: '___.___.___-__'});
      }, 1);
    } else {
      setTimeout(function () {
        $(maskCGC).unmask();
        $(maskCGC).mask('00.000.000/0000-00', {placeholder: '__.___.___/____-__'});
      }, 1);
    }
  }

  pesquisarCep() {
    this.carregadoCorreio = false;

    this.credenciadoService.getCepCorreios({cep: this.credenciado.cep.replace('.', '')})
      .subscribe(
        response => {
          if (response.success) {
            response = response.dados;

            let end = '';
            let bairro = response.bairro;

            if (response.logradouro) {
              end = response.logradouro;

              if (response.logradouro.length > 50) {
                end = response.logradouro.substr(0, 50);
              }
            }

            if (response.bairro.length > 25) {
              bairro = response.bairro.substr(0, 25);
            }

            this.credenciado.endereco = end;
            this.credenciado.bairro = bairro;
            this.credenciado.estado = response.estado;
            this.credenciado.cidade = this.removerAcentos(response.cidade.toUpperCase());

            this.getCidades(response.estado, true);

            this.carregadoCorreio = true;
          } else {
            swal({
              title: 'CEP Inválido!',
              text: 'Desculpa, mas o cep informado não foi encontrado.',
              type: 'error',
              closeOnConfirm: true
            });
          }
        });
  }

  getCidades(estado, inicio) {
    if (!inicio) {
      this.credenciado.cidade = '';
    }

    this.credenciadoService.getCidades({uf: estado})
      .subscribe(
        response => {
          this.cidades = response.cidades;
        }
      );
  }

  pesquisarCepCorres() {
    this.carregadoCorreioCorres = false;

    this.correiosService.getCepCorreios(this.credenciado.cepCorres)
      .subscribe(
        response => {
          if (response) {
            this.credenciado.enderecoCorres = response.logradouro;
            this.credenciado.bairroCorres = response.bairro;
            this.credenciado.estadoCorres = response.estado;
            this.credenciado.cidadeCorres = this.removerAcentos(response.cidade.toUpperCase());

            this.getCidadesCorres(response.estado, true);

            this.carregadoCorreioCorres = true;
          }
        },
        error => {
          if (error.status == 404) {
            swal({
              title: 'CEP Inválido!',
              text: 'Desculpa, mas o cep informado não foi encontrado.',
              type: 'error',
              closeOnConfirm: true
            });
          }
        }
      );
  }

  getCidadesCorres(estado, inicio) {
    if (!inicio) {
      this.credenciado.cidadeCorres = '';
    }

    this.credenciadoService.getCidades({uf: estado})
      .subscribe(
        response => {
          this.cidadesCorres = response.cidades;
        }
      );
  }

  verificar(): void {
    this.CGCValidado = false;

    if ((this.credenciado.tipoCgc == '2' && this.validaCNPJ(this.credenciado.cgc)) || (this.credenciado.tipoCgc == '1' && this.validaCPF(this.credenciado.cgc))) {
      this.carregadoCNPJ = false;

      this.credenciadoService.veridicarCGC({cgc: this.credenciado.cgc})
        .subscribe(
          response => {
            if (response.success) {
              this.CGCValidado = true;
              this.initMask();
            } else {
              swal({
                title: 'Problema',
                text: response.message,
                type: 'error',
                closeOnConfirm: true
              });
            }

            this.carregadoCNPJ = true;
          }
        );
    } else {
      swal({
        title: 'Problema',
        text: 'CPF/CNPJ informado inválido, favor verificar novamente.',
        type: 'error',
        closeOnConfirm: true
      });
    }
  }

  enviar(): void {
    this.salvando = true;

    if (this.valorconsulta) {
      this.credenciado.valorConsulta = $(this.valorconsulta.nativeElement).val().toString();
    }

    if (this.valortaxa) {
      this.credenciado.valorTaxa = $(this.valortaxa.nativeElement).val().toString();
    }

    this.credenciado.valorTaxa2 = $(this.valortaxa2.nativeElement).val().toString();
    this.credenciado.valorTaxaPublico = $(this.valorTaxaPublico.nativeElement).val().toString();
    this.credenciado.cgc = $(this.cgc.nativeElement).val().toString();

    this.credenciado.socio1cpf = $(this.socio1cpf.nativeElement).val().toString();
    this.credenciado.socio2cpf = $(this.socio2cpf.nativeElement).val().toString();

    if (this.valorPOS) {
      this.credenciado.valorPOS = $(this.valorPOS.nativeElement).val().toString();
    }

    if (this.valorWEB) {
      this.credenciado.mensalidadeWeb = $(this.valorWEB.nativeElement).val().toString();
    }

    if (this.valorTEF) {
      this.credenciado.mensalidadeTef = $(this.valorTEF.nativeElement).val().toString();
    }

    if (this.valorCIELO) {
      this.credenciado.mensalidadeCielo = $(this.valorCIELO.nativeElement).val().toString();
    }

    if (this.valorGETNET) {
      this.credenciado.mensalidadeGetnet = $(this.valorGETNET.nativeElement).val().toString();
    }

    if (this.valorSTONE) {
      this.credenciado.mensalidadeStone = $(this.valorSTONE.nativeElement).val().toString();
    }

    if (this.valorFIRST) {
      this.credenciado.mensalidadeFirst = $(this.valorFIRST.nativeElement).val().toString();
    }
    if (this.valorREDE) {
      this.credenciado.mensalidadeRede = $(this.valorREDE.nativeElement).val().toString();
    }

    if (this.especialidade) {
      let espcc: any = $(this.especialidade.nativeElement).val();
      this.credenciado.especialidades = espcc;
    }

    if (this.credenciado.plusfrota == true) {
      if (this.AddRowHDF.length > 0) {
        this.credenciado.AddRowHDF = this.AddRowHDF;
      } else {
        swal('Erro', 'Você deve informar pelo menos um horario de funcionamento', 'error');
      }
    }

    let me = this;
    let previousWindowKeyDown = window.onkeydown;


    if (this.validaTudo()) {
      if ((this.credenciado.tipoCgc == '2' && this.validaCNPJ(this.credenciado.cgc)) || (this.credenciado.tipoCgc == '1' && this.validaCPF(this.credenciado.cgc))) {
        swal({
          title: 'Credenciado',
          text: 'Deseja confirmar o credenciamento?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          closeOnConfirm: false,
          showLoaderOnConfirm: true
        }, function () {
          window.onkeydown = previousWindowKeyDown;

          me.credenciadoService.add(me.credenciado)
            .subscribe(
              response => {
                if (response.success) {
                  swal({
                    title: 'Credenciado',
                    text: 'Credenciado cadastrado com sucesso.',
                    type: 'success',
                    closeOnConfirm: true
                  }, function () {
                    me.router.navigate(['credenciados'])
                  });
                } else {
                  swal({
                    title: 'Credenciado',
                    text: response.message,
                    type: 'error',
                    closeOnConfirm: true
                  });

                  me.salvando = false;
                }
              }
            );
        });
      } else {
        swal({
          title: 'Problema',
          text: 'CPF/CNPJ informado inválido, favor verificar novamente.',
          type: 'error',
          closeOnConfirm: true
        });

        this.salvando = false;
      }
    } else {
      swal({
        title: 'Problema',
        text: `Existe um ou mais campo invalido: ${this.formError}`,
        type: 'error',
        closeOnConfirm: true
      });

      this.salvando = false;
    }
  }

  validaTudo(): boolean {
    let retorno = true;

    this.formError = '';

    if (this.validaVazio()) {
      if (this.credenciado.pos && this.credenciado.aluguelPos == 'S' && parseFloat(this.credenciado.valorPOS) <= 0) {
        this.formError = 'Por favor, preencha o valor do POS.';
        retorno = false;
      }
      if (parseInt(this.credenciado.prazoPagamento) <= 0) {
        this.formError = 'Por favor, o prazo de pagamento deve ser maior que zero.';
        retorno = false;
      }

      if (this.credenciado.fechamento == 'S') {
        if (this.credenciado.diaFechamento.trim().toUpperCase() != 'SEGUNDA' &&
          this.credenciado.diaFechamento.trim().toUpperCase() != 'TERCA' &&
          this.credenciado.diaFechamento.trim().toUpperCase() != 'QUARTA' &&
          this.credenciado.diaFechamento.trim().toUpperCase() != 'QUINTA' &&
          this.credenciado.diaFechamento.trim().toUpperCase() != 'SEXTA' &&
          this.credenciado.diaFechamento.trim().toUpperCase() != 'SABADO' &&
          this.credenciado.diaFechamento.trim().toUpperCase() != 'DOMINGO') {
          this.formError = 'Por favor, informe um dia de fechamento válido. Tente: SEGUNDA,TERCA,QUARTA,QUINTA,SEXTA,SABADO,DOMINGO.';
          retorno = false;
        }
      } else if (this.credenciado.fechamento == 'Q') {
        try {
          if (parseInt(this.credenciado.diaFechamento) <= 0 || parseInt(this.credenciado.diaFechamento) > 15) {
            this.formError = 'Por favor, informe um dia de fechamento válido. Tente: 1 a 15.';
            retorno = false;
          }
        } catch (e) {
          this.formError = 'Por favor, informe um dia de fechamento válido.';
        }
      } else {
        try {
          if (parseInt(this.credenciado.diaFechamento) <= 0 || parseInt(this.credenciado.diaFechamento) > 31) {
            this.formError = 'Por favor, informe um dia de fechamento válido. Tente: 1 a 31.';
            retorno = false;
          }
        } catch (e) {
          this.formError = 'Por favor, informe um dia de fechamento válido.';
        }
      }
    } else {
      retorno = false;
    }

    return retorno;
  }

  validaVazio(): boolean {
    let retorno = true;

    /*
    if (this.credenciado.rede.trim() == '') {
      this.formError = 'Por favor, preencha a Rede Referênciada.';
      retorno = false;
    } 
    */
    if (this.credenciado.razao.trim() == '') {
      this.formError = 'Por favor, preencha a razão social.';
      retorno = false;
    } 

    if (this.credenciado.fantasia.trim() == '') {
      this.formError = 'Por favor, preencha o nome fantasia.';
      retorno = false;
    } 

    if (this.credenciado.cgc.trim() == '') {
      this.formError = 'Por favor, preencha o CGC.';
      retorno = false;
    } 

    if (this.credenciado.inscricao.trim() == '') {
      this.formError = 'Por favor, preencha a inscrição estadual.';
      retorno = false;
    } 

    if (this.credenciado.segmento.trim() == '') {
      this.formError = 'Por favor, informe o segmento.';
      retorno = false;
    } 

    if (this.credenciado.ramo.trim() == '') {
      this.formError = 'Por favor, informe o ramo de atividade.';
      retorno = false;
    } 

    if (this.credenciado.telefone.trim() == '') {
      this.formError = 'Por favor, preencha o telefone.';
      retorno = false;
    } else if (this.credenciado.telefone.trim().length < 13) {
      this.formError = 'Por favor, informe o telefone corretamente.';
    } 

    if (this.credenciado.contato.trim() == '') {
      this.formError = 'Por favor, preencha o contato.';
      retorno = false;
    } 

    if (this.credenciado.email.trim() == '') {
      this.formError = 'Por favor, preencha o e-mail.';
      retorno = false;
    } else {
      this.credenciado.email.replace(/[\(\)\.\s-]+/g, ';');
    }



    if (this.credenciado.responsavel.trim() == '') {
      this.formError = 'Por favor, preencha o responsavel pela empresa.';
      retorno = false;
    } 

    if (this.credenciado.cep.trim() == '') {
      this.formError = 'Por favor, preencha o CEP.';
      retorno = false;
    } 

    if (this.credenciado.estado.trim() == '') {
      this.formError = 'Por favor, informe o estado.';
      retorno = false;
    } 

    if (this.credenciado.endereco.trim() == '') {
      this.formError = 'Por favor, preencha o endereço.';
      retorno = false;
    } 

    if (this.credenciado.bairro.trim() == '') {
      this.formError = 'Por favor, preencha o bairro.';
      retorno = false;
    } 

    if (this.credenciado.cidade.trim() == '') {
      this.formError = 'Por favor, informe a cidade.';
      retorno = false;
    } 

    if (this.credenciado.socio1nome.trim() == '') {
      this.formError = 'Por favor, preencha o nome do sócio 1.';
      retorno = false;
    } 

    if (this.credenciado.endCorres == 'D') {
      if (this.credenciado.cepCorres.trim() == '') {
        this.formError = 'Por favor, preencha o CEP de correspondencia.';
        retorno = false;
      } 

      if (this.credenciado.estadoCorres.trim() == '') {
        this.formError = 'Por favor, informe o estado de correspondencia.';
        retorno = false;
      } 

      if (this.credenciado.enderecoCorres.trim() == '') {
        this.formError = 'Por favor, preencha o endereço de correspondencia.';
        retorno = false;
      } 

      if (this.credenciado.bairroCorres.trim() == '') {
        this.formError = 'Por favor, preencha o bairro de correspondencia.';
        retorno = false;
      } 

      if (this.credenciado.cidadeCorres.trim() == '') {
        this.formError = 'Por favor, informe a cidade de correspondencia.';
        retorno = false;
      } 
    }

    if (this.credenciado.banco.trim() == '') {
      this.formError = 'Por favor, informe o banco.';
      retorno = false;
    } 

    if (this.credenciado.agencia.trim() == '') {
      this.formError = 'Por favor, preencha a agencia.';
      retorno = false;
    } 

    if (this.credenciado.conta.trim() == '') {
      this.formError = 'Por favor, preencha a conta bancaria.';
      retorno = false;
    } 

    if (this.credenciado.localPgto == 'C' && this.credenciado.codCentralizadora.trim() == '') {
      this.formError = 'Por favor, preencha o CNPJ da Centralizadora.';
      retorno = false;
    } 

    if (this.credenciado.diaFechamento.trim() == '') {
      this.formError = 'Por favor, preencha o dia do fechamento.';
      retorno = false;
    } 

    if (this.credenciado.prazoPagamento.trim() == '') {
      this.formError = 'Por favor, preencha o prazo de pagamento.';
      retorno = false;
    } 

    if (this.credenciado.valorTaxa2.trim() == '') {
      this.formError = 'Por favor, informe a taxa administrativa.';
      retorno = false;
    } 

    if (this.credenciado.taxaAdm && this.credenciado.valorTaxa.trim() == '') {
      this.formError = 'Por favor, preencha o valor da taxa de filiação.';
      retorno = false;
    } 

    if (!this.credenciado.ura && !this.credenciado.web && !this.credenciado.pos
      && !this.credenciado.tef && !this.credenciado.pago && !this.credenciado.cielo && !this.credenciado.getnet && !this.credenciado.stone && !this.credenciado.first
      && !this.credenciado.tecRede) {
        this.formError = 'Por favor, informe pelo menos 1 tecnologia.';
        retorno = false;
      } 

    if (this.credenciado.pos && this.credenciado.aluguelPos == 'S' && this.credenciado.valorPOS.trim() == '') {
      this.formError = 'Por favor, preencha o valor do POS.';
      retorno = false;
    } 

    if (this.credenciado.cielo && this.credenciado.ec.trim() == '') {
      this.formError = 'Por favor, preencha o EC.';
      retorno = false;
    } 
    if (this.credenciado.tecRede && this.credenciado.pv.trim() == '') {
      this.formError = 'Por favor, preencha o PV.';
      retorno = false;
    } 

    if (this.credenciado.plusfrota == true) {
      if (this.credenciado.AddRowHDF.length == 0) {
        this.formError = 'Por favor, inserrir horario de funcinamento.';
        retorno = false;
      } 
    }

    return retorno;
  }

  removerAcentos(s) {
    let map = {'â': 'a', 'Â': 'A', 'à': 'a', 'À': 'A', 'á': 'a', 'Á': 'A', 'ã': 'a', 'Ã': 'A',
               'ê': 'e', 'Ê': 'E', 'è': 'e', 'È': 'E', 'é': 'e', 'É': 'E', 'î': 'i', 'Î': 'I',
               'ì': 'i', 'Ì': 'I', 'í': 'i', 'Í': 'I', 'õ': 'o', 'Õ': 'O', 'ô': 'o', 'Ô': 'O',
               'ò': 'o', 'Ò': 'O', 'ó': 'o', 'Ó': 'O', 'ü': 'u', 'Ü': 'U', 'û': 'u', 'Û': 'U',
               'ú': 'u', 'Ú': 'U', 'ù': 'u', 'Ù': 'U', 'ç': 'c', 'Ç': 'C'};
    return s.replace(/[\W\[\] ]/g, function(a) { return map[a] || a });
  }

  consultarReceita(): void {
    this.carregadoCNPJ = false;

    if (this.captchaDigitada) {
      $(this.captchaDigitada.nativeElement).val('');
    }

    this.credenciadoService.getCaptcha()
      .subscribe(
        response => {
          if (response.img != '') {
            if (response.img == 'data:image/png;base64,') {
              swal({
                title: 'Consulta CNPJ',
                text: 'Serviço da receita instável, tente novamente.',
                type: 'error',
                closeOnConfirm: true
              });
            } else {
              this.captcha = response.img;

              this.mostrarConsulta = true;
              this.modalReceita.show();
            }
          } else {
            swal({
              title: 'Consulta CNPJ',
              text: 'Serviço da receita não esta disponivel.',
              type: 'error',
              closeOnConfirm: true
            });
          }

          this.carregadoCNPJ = true;
        }
      );
  }

  importar(captcha): void {
    this.credenciadoService.importar({captcha: captcha, cnpj: this.credenciado.cgc})
      .subscribe(
        response => {
          console.log(response);

          if (response.status == 'Imagem digitada incorretamente') {
            swal({
              title: 'Consulta CNPJ',
              text: response.status,
              type: 'error',
              closeOnConfirm: true
            });

            this.consultarReceita();
          }
        }
      );
  }

  validaCNPJ(cnpj): boolean {
    let vCnpj = cnpj.split('.').join('').split('/').join('').split('-').join('');

    if (vCnpj.length != 14) {
      return false;
    }

    let digitos = [];
    let soma = [0, 0];
    let resultado = [0, 0];

    let ftmt = '6543298765432';
    let CNPJOk = [false, false];

    try {
      for (let nrDig = 0; nrDig < 14; nrDig++) {
        digitos.push(vCnpj.substr(nrDig, 1) * 1);

        if (nrDig <= 11) {
          let teste: any = ftmt.substr(nrDig + 1, 1);
          soma[0] += (digitos[nrDig] * teste);
        }

        if (nrDig <= 12) {
          let teste: any = ftmt.substr(nrDig, 1);
          soma[1] += (digitos[nrDig] * teste);
        }
      }

      for (let nrDig = 0; nrDig < 2; nrDig++ ) {
        resultado[nrDig] = (soma[nrDig] % 11);

        if ((resultado[nrDig] == 0) || (resultado[nrDig] == 1)) {
          CNPJOk[nrDig] = (digitos[12 + nrDig] == 0);
        } else {
          CNPJOk[nrDig] = (digitos[12 + nrDig] == (11 - resultado[nrDig]));
        }
      }

      return (CNPJOk[0] && CNPJOk[1]);
    } catch {
      return false;
    }
  }

  validaCPF(cpf): boolean {
    let valor = cpf.split('.').join('').split('-').join('');

    if (valor.length != 11) {
      return false;
    }


    let igual = true;

    for (let i = 1; i < 11 && igual; i++) {
      if (valor[i] != valor[0]) {
        igual = false;
      }

    }

    if (igual || valor == '12345678909') {
      return false;
    }


    let numeros = [];

    for (let i = 0; i < 11; i++) {
      numeros.push(valor[i] * 1);
    }

    let soma = 0;

    for (let i = 0; i < 9; i++) {
      soma += (10 - i) * numeros[i];
    }

    let resultado = soma % 11;

    if (resultado == 1 || resultado == 0) {
      if (numeros[9] != 0) { return false; }
    } else {
      if (numeros[9] != 11 - resultado) {
        return false;
      }

    }

    soma = 0;

    for (let i = 0; i < 10; i++) {
      soma += (11 - i) * numeros[i];
    }


    resultado = soma % 11;

    if (resultado == 1 || resultado == 0) {
      if (numeros[10] != 0) {
        return false;
      }
    } else {
      if (numeros[10] != 11 - resultado) {
        return false;
      }
    }

    return true;
  }

  addRowHDF(): void {
    this.AddRowHDF.push({
      tipoHorario: 1,
      seg: false, ter: false, qua: false, qui: false, sex: false, sab: false, dom: false,
      periodoA: '', periodoB: ''});
    this.initMask();
  }

  setRowHDF(row): void {
    if (row.tipoHorario == 1) {
      row.seg = false; row.ter = false; row.qua = false; row.qui = false; row.sex = false; row.sab = false; row.dom = false;
      row.periodoA = ''; row.periodoB = ''
    }
    if (row.tipoHorario == 2) {
      row.seg = true; row.ter = true; row.qua = true; row.qui = true; row.sex = true; row.sab = true; row.dom = true;
      row.periodoA = '00:00'; row.periodoB = '23:59'
    }
    if (row.tipoHorario == 3) {
      row.seg = true; row.ter = true; row.qua = true; row.qui = true; row.sex = true; row.sab = false; row.dom = false;
      row.periodoA = '00:00'; row.periodoB = '23:59'
    }
  }

  setRowHDFSemana(pos, semana): void {
    switch (semana) {
      case 2:
        this.AddRowHDF[pos].seg = !this.AddRowHDF[pos].seg;
        break;
      case 3:
        this.AddRowHDF[pos].ter = !this.AddRowHDF[pos].ter;
        break;
      case 4:
        this.AddRowHDF[pos].qua = !this.AddRowHDF[pos].qua;
        break;
      case 5:
        this.AddRowHDF[pos].qui = !this.AddRowHDF[pos].qui;
        break;
      case 6:
        this.AddRowHDF[pos].sex = !this.AddRowHDF[pos].sex;
        break;
      case 7:
        this.AddRowHDF[pos].sab = !this.AddRowHDF[pos].sab;
        break;
      case 1:
        this.AddRowHDF[pos].dom = !this.AddRowHDF[pos].dom;
        break;
    }

  }

  delRowHDF(index): void {
    this.AddRowHDF.splice(index, 1);
  }

  altMaskTel(valor): void {
    let maskFone = '.maskFoneTel';

    let tel = valor.replace(/[\s\-\(\)]/g, '');

    if (tel.length >= 4 && tel.substr(0, 4) == '0800') {
      $(maskFone).mask('0000 000 0000');
    } else if (tel.length >= 11) {
      $(maskFone).mask('(00) 00000-0000');
    } else {
      $(maskFone).mask('(00) 0000-00000');
    }

    setTimeout(function () {
      let val = $(maskFone).val();
      $(maskFone).val('').val(val);
    }, 1);
  }
}
