import {Observable} from 'rxjs/Observable';
import 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceMain} from './service-main.service';
import {Router} from '@angular/router';
import {PersistenceService} from 'angular-persistence';

@Injectable()
export class ProspectService extends ServiceMain {
  filtro = {
    paginaAtual: 1,
    paginaFinal: 1,
    limite: '10',

    cod: '',
    datainc: '',
    nome: '',
    dataret: '',
    municipio: '',
    uf: '',
    solicitante: '',
    status: ''
  };

  constructor(private _http: HttpClient,
              private routers: Router,
              private persistenceService: PersistenceService) {
    super(_http, routers, persistenceService);
  }

  get(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'prospect');
  }

  getEsp(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'prospect/esp')
  }

  att(dados: any): Observable<any> {
    return this.executaPOST(dados, 'prospect')
  }
}
